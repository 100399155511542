<template>
  <div>
    <div class="message-center-bg">
      <div class="message-center-con">
        <b-container fluid>
          <h2 class="page-title">信息中心</h2>
          <div class="message-list">
            <table>
              <thead>
                <tr>
                  <th>头像</th>
                  <th>昵称</th>
                  <th>电话</th>
                  <th>消息</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in msgItems" :key="index">
                  <td>
                    <div class="avatar">
                      <img :src="item.imgUrl" :alt="item.name">
                    </div>
                  </td>
                  <td>
                    <div class="name">{{item.name}}</div>
                  </td>
                  <td>
                    <div class="phone">{{item.phone}}</div>
                  </td>
                  <td>
                    <div class="msg" v-if="item.comments">{{item.comments.commentContents}}</div>
                  </td>
                  <td>
                    <div class="operation">
                      <span class="reply" @click="openReply(item, index)">回复</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-container>
      </div>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="replyDialog"
      width="30%"
      center>
      <el-form ref="form" :model="form">
        <el-form-item>
          <el-input type="textarea" resize="none" rows="3" v-model="form.replyMsg" @input="replyMsgChange(form.key)"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="replyDialog = false">取 消</el-button>
        <el-button type="primary" @click="msgSend(form.index)">发 送</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import dump from "@/util/dump.js";
  export default {
    data(){
      return {
        token: null,
        title: '',
        replyDialog: false,
        form: {
          replyMsg: '',
          key: '',
          index: ''
        },
        msgItems: []
      }
    },
    created() {
      this.init();
    },
    methods: {
      init(){
        this.token = localStorage.getItem("token");
        this.getMessages();
      },
      // 获取信息
      getMessages(){
        this.$api.getCommentItems({
          projectId: 1,
          projectType: 4,
          token: this.token
        }).then(res => {
          if(res.status == 100){
            this.msgItems = res.data.comments;
          }
        }).catch(err => console.error(err))
      },
      // 开启回复窗口
      openReply(obj, idx){
        if (!dump.checkLogin()) {
          return false;
        }
        this.replyDialog = !this.replyDialog;
        this.title = `回复${obj.name}的消息`;
        this.form.key = obj.name + idx;
        this.form.index = idx;
        // 查询是否有编辑的缓存信息
        this.form.replyMsg = this.msgUtil().getMsg()[this.form.key] || '';
      },
      // 发送回复消息
      msgSend(idx){
        
        // commentReply
        this.$api.commentReply({
          // 回复类型:(1 回复评论 2回复回复)
          replayType: 1,
          // 回复目标ID:回复ID/评论ID
          replayTargetId: this.msgItems[idx].comments.commentId,
          // 被评论者ID
          toUserId: this.msgItems[idx].comments.fromUserId,
          // 评论信息
          replayContents: this.form.replyMsg,
          token: this.token
        }).then(res => {
          if(res.status == 100){
            // 删除临时存储消息
            this.msgUtil().delete(this.form.key);
            this.form.replyMsg = '';
            this.replyDialog = !this.replyDialog;
            this.$message({
              message: res.data,
              type: 'success'
            });
          }
        }).catch(err => console.error(err));
      },
      // 
      replyMsgChange(key){
        // 存储或修改消息
        this.msgUtil().addMsg(key, this.form.replyMsg)
      },
      // 消息处理函数
      msgUtil(){
        let msg = JSON.parse(window.sessionStorage.getItem('msgObj') || "{}");
        // console.log(msg);
        const msgUtilFn = {
          // 添加信息
          addMsg(key, val){
            msg[key] = val;
            // 把更新后的数据保存到本地临时存储
            this.saveMsg(msg);
          },
          // 删除消息
          delete(key){
            delete msg[key];
            this.saveMsg(msg);
          },
          // 查找信息
          getMsg(){
            return JSON.parse(window.sessionStorage.getItem("msgObj") || "{}");
          },
          // 存储添加的消息到H5本地临时存储
          saveMsg(){
            window.sessionStorage.setItem("msgObj", JSON.stringify(msg));
          }
        }
        return msgUtilFn;
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "@/assets/scss/userCenterCommon";
  @import "@/view/userCenter/messageCenter/messageCenter";
</style>
